/* 5 Components
----------------------------------------------------------------------------- */
/* Buttons
---------------------------------- */
/*Buttons Color Variations*/
.btn:focus {
	color: inherit;
}
.btn {
	.transition(all 0.2s ease-in-out);
}
/*Default Button*/
.btn-default {
	color: @white;
	background-color: @base-color;
	border-color: @base-color-hover;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
	color: @white;
	background-color: @base-color-hover;
	border-color: @base-color-hover;
}
.btn-default.disabled, 
.btn-default[disabled], 
fieldset[disabled] .btn-default {
	background-color: @base-color;
	border-color: @base-color-hover;
}
.btn-default-transparent {
	color: @gray;
	background-color: transparent;
	border-color: @base-color;

	&:hover,
	&:focus,
	&.focus,
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		color: #ffffff;
		background-color: @base-color-hover;
		border-color: darken(@base-color, 12%);
	}
}
.dark-bg {
	.btn-default-transparent {
	  color: #ffffff;
	}
}
.default-bg,
.default-translucent-bg {
	.btn-default,
	.btn-default-transparent {
		color: @base-color;
		background-color: @white;
		border-color: @white;
	}
	.btn-default:hover,
	.btn-default:focus,
	.btn-default.focus,
	.btn-default:active,
	.btn-default.active,
	.open > .dropdown-toggle.btn-default,
	.btn-default-transparent:hover,
	.btn-default-transparent:focus,
	.btn-default-transparent.focus,
	.btn-default-transparent:active,
	.btn-default-transparent.active,
	.open > .dropdown-toggle.btn-default-transparent {
		color: @white;
		background-color: transparent;
		border-color: @white;
	}
}
.dark {
	.btn-default {
		color: @white;
		background-color: rgba(0,0,0,0.2);
		border-color: rgba(0,0,0,0.1);
		.badge {
			color: rgba(0,0,0,0.2);
			background-color: @white;
		}
	}
	.btn-default:hover,
	.btn-default:focus,
	.btn-default.focus,
	.btn-default:active,
	.btn-default.active,
	.open > .dropdown-toggle.btn-default {
		color: @white;
		background-color: rgba(0, 0, 0, 0.35);
		border-color: rgba(0,0,0,0.2);
	}
}
.colored {
	.btn-default {
		color: @white;
		background-color: rgba(0,0,0,0.2);
		border-color: rgba(0,0,0,0.01);
		.badge {
			color: rgba(0,0,0,0.2);
			background-color: @white;
		}
	}
	.btn-default:hover,
	.btn-default:focus,
	.btn-default.focus,
	.btn-default:active,
	.btn-default.active,
	.open > .dropdown-toggle.btn-default {
		color: @white;
		background-color: rgba(0,0,0,0.3);
		border-color: rgba(0,0,0,0.02);
	}
}
.default-hovered:hover {
	.btn-default-transparent {
		border-color: #ffffff;
	}
}
/*White Button*/
.btn-white {
	color: @gray;
	background-color: @white;
	border-color: @white;
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white:active,
.btn-white.active,
.open > .dropdown-toggle.btn-white {
	color: @base-color-hover;
	background-color: @white;
	border-color: @white;
}
/*Gray Button*/
.btn-gray {
	color: @gray-dark;
	background-color: #e1e1e1;
	border-color: #d3d3d3;
}
.btn-gray:hover,
.btn-gray:focus,
.btn-gray.focus,
.btn-gray:active,
.btn-gray.active,
.open > .dropdown-toggle.btn-gray {
	color: @gray-dark;
	background-color: #cdcdcd;
	border-color: #cdcdcd;
}
.btn-gray-transparent {
	color: @gray-dark;
	background-color: transparent;
	border-color: @gray;
}
.btn-gray-transparent:hover,
.btn-gray-transparent:focus,
.btn-gray-transparent.focus,
.btn-gray-transparent:active,
.btn-gray-transparent.active,
.open > .dropdown-toggle.btn-gray-transparent {
	color: @white;
	background-color: @gray-dark;
	border-color: @gray-dark;
}
.dark-bg {
	.btn-gray-transparent {
		color: #ffffff;
	}
	.btn-gray-transparent:hover {
		border-color: #ffffff;
		color: @gray;
		background-color: #ffffff;
	}
}
.dark-translucent-bg,
.default-translucent-bg,
.default-bg {
	.btn-gray-transparent {
		border-color: #ffffff;
		color: #ffffff;
	}
	.btn-gray-transparent:hover,
	.btn-gray-transparent:focus {
		border-color: #ffffff;
		color: @gray;
		background-color: #ffffff;
	}
}
.default-hovered:hover {
	.btn-gray-transparent {
		border-color: #ffffff;
	}
}
.light-gray-bg.default-hovered:hover {
	.btn-gray-transparent:hover {
		border-color: @gray-dark;
	}
}
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) {
	.two-col-translucent-bg .container > .row > div + div {
		.btn-gray-transparent {
			border-color: #ffffff;
			color: #ffffff;
		}
		.btn-gray-transparent:hover,
		.btn-gray-transparent:focus {
			border-color: #ffffff;
			color: @gray;
			background-color: #ffffff;
		}
	}
}
/*Dark Button*/
.btn-dark {
	color: #ffffff;
	background-color: #373737;
	border-color: #333333;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark.focus,
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
	color: #ffffff;
	background-color: #404040;
	border-color: #404040;
}
.dark-bg {
	.btn-dark {
		color: #ffffff;
		background-color: #222222;
		border-color: #171717;
	}
	.btn-dark:hover,
	.btn-dark:focus,
	.btn-dark.focus,
	.btn-dark:active,
	.btn-dark.active,
	.open > .dropdown-toggle.btn-dark {
		color: #ffffff;
		background-color: #000000;
		border-color: #000000;
	}
}
/*Buttons Sizes*/
.btn {
	.button-size(7px; 20px; 14px; @line-height-base; 3px);
	margin: 10px 0;
}
.btn-sm {
	.button-size(5px; 15px; 12px; @line-height-base; 3px);
	margin: 5px 0;
}
.btn-lg {
	.button-size(10px; 25px; 18px; @line-height-base; 4px);
	margin: 10px 0;
}
.btn-md-link {
	.button-size(7px; 15px; 14px; @line-height-base; 0px);
	margin: 10px 0;
}
.btn-sm-link {
	.button-size(5px; 15px; 12px; @line-height-base; 0px);
	margin: 5px 0;
}
.btn-lg-link {
	.button-size(10px; 15px; 18px; @line-height-base; 0px);
	margin: 10px 0;
}
.btn-remove {
	font-size: 10px;
	padding: 3px 15px;
	min-width: 0;
	margin: -5px 0 0 0;
}
/*Buttons with radius*/
.radius-50 {
	.border-radius(25px);
}
/*Animated Buttons*/
.btn-animated {
	position: relative;
	min-width: 0!important;
	padding-right: 45px!important;
}
.btn-animated i {
	.transition(right 0.2s ease-in-out);
	z-index: 3;  
	right: 17px;
	position: absolute;
	line-height: 34px;
	top: 0;
	min-width: 12px;
}
.btn-animated:after {
	width: 35px;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	position: absolute;
	content: "";
	top: 0;
	.opacity(0);
	right: 0px;
	z-index: 2;
	.transition(all 0.2s ease-in-out);
}
.btn-animated.radius-50:after { 
	.border-radius(0 25px 25px 0);
}
.btn-animated.btn-gray-transparent:after {
	background-color: rgba(0, 0, 0, 0.05);
}
.btn-animated:hover:after {
	.opacity(1);
}
.btn-animated:hover i { 
	right: 11px;	
}
.btn-animated.btn-sm {
	padding-right: 35px!important;
}
.btn-animated.btn-sm:after {
	width: 25px;
}
.btn-animated.btn-sm i {
	right: 14px;
	line-height: 28px;
	min-width: 11px;
}
.btn-animated.btn-sm:hover i {
	right: 7px;
}
.btn-animated.btn-lg {
	padding-right: 60px!important;	
}
.btn-animated.btn-lg:after {
	width: 45px;
}
.btn-animated.btn-lg i {
	right: 25px;
	line-height: 48px;
	min-width: 15px;
}
.btn-animated.btn-lg:hover i { 
	right: 15px;
}
/*Background Transition*/
.btn-hvr {
	.transition(all 0.4s ease-in-out);
}
.btn-hvr.radius-50 {
	overflow: hidden;
}
.hvr-sweep-to-right:hover,
.hvr-sweep-to-left:hover,
.hvr-sweep-to-bottom:hover,
.hvr-sweep-to-top:hover,
.hvr-bounce-to-right:hover,
.hvr-bounce-to-left:hover,
.hvr-bounce-to-bottom:hover,
.hvr-bounce-to-top:hover,
.hvr-radial-out:hover,
.hvr-rectangle-out:hover,
.hvr-shutter-out-horizontal:hover,
.hvr-shutter-out-vertical:hover {
	background: transparent;
}
.hvr-radial-in,
.hvr-rectangle-in,
.hvr-shutter-in-horizontal,
.hvr-shutter-in-vertical {
	background: @base-color-hover;
}
.hvr-radial-in:hover,
.hvr-rectangle-in:hover,
.hvr-shutter-in-horizontal:hover,
.hvr-shutter-in-vertical:hover {
	background: @base-color-hover;
}
.hvr-radial-in:before,
.hvr-rectangle-in:before,
.hvr-shutter-in-horizontal:before,
.hvr-shutter-in-vertical:before { 
	background: @white;
	.border-radius(3px);
}
.hvr-sweep-to-right:before,
.hvr-sweep-to-left:before,
.hvr-sweep-to-bottom:before,
.hvr-sweep-to-top:before,
.hvr-bounce-to-right:before,
.hvr-bounce-to-left:before,
.hvr-bounce-to-bottom:before,
.hvr-bounce-to-top:before,
.hvr-radial-out:before,
.hvr-rectangle-out:before,
.hvr-shutter-out-horizontal:before,
.hvr-shutter-out-vertical:before { 
	background: @base-color-hover;
}
/*ie9*/
.no-csstransitions .hvr-radial-in,
.no-csstransitions .hvr-rectangle-in,
.no-csstransitions .hvr-shutter-in-horizontal,
.no-csstransitions .hvr-shutter-in-vertical {
	background: transparent;
}
.no-csstransitions .hvr-sweep-to-right:hover,
.no-csstransitions .hvr-sweep-to-left:hover,
.no-csstransitions .hvr-sweep-to-bottom:hover,
.no-csstransitions .hvr-sweep-to-top:hover,
.no-csstransitions .hvr-bounce-to-right:hover,
.no-csstransitions .hvr-bounce-to-left:hover,
.no-csstransitions .hvr-bounce-to-bottom:hover,
.no-csstransitions .hvr-bounce-to-top:hover,
.no-csstransitions .hvr-radial-out:hover,
.no-csstransitions .hvr-rectangle-out:hover,
.no-csstransitions .hvr-shutter-out-horizontal:hover,
.no-csstransitions .hvr-shutter-out-vertical:hover,
.no-csstransitions .hvr-radial-in:hover,
.no-csstransitions .hvr-rectangle-in:hover,
.no-csstransitions .hvr-shutter-in-horizontal:hover,
.no-csstransitions .hvr-shutter-in-vertical:hover {
	background: @base-color-hover;
}
/* Tabs
---------------------------------- */
.nav-tabs.style-1 {
	border-bottom: 1px solid #e1e1e1;
	margin-bottom: 15px;
	> li {
		// Actual tabs (as links)
		> a {
			color: #333333;
			margin-right: 0;
			line-height: 1;
			border: 1px solid transparent;
			padding: 12px 15px 11px 15px;
			.border-radius(15px 15px 0 0);
			.transition(none);
			height: 40px;
			&:hover {
				border-color: #222222!important;
				background-color: #333333!important;
				color: #ffffff;
			}			
			&:focus {
				border-color: transparent;
				background-color: transparent;
			}
			&:after {
				position: absolute;
				right: -14px;
				bottom: -1px;
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 31px 0 0 14px;
				border-color: transparent transparent transparent transparent;
			}
			&:before {
				position: absolute;
				left: -14px;
				bottom: -1px;
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 31px 14px 0 0;
				border-color: transparent transparent transparent transparent;
			}
			&:hover:after { 
				border-color: transparent transparent transparent #333333;
				border-width: 31px 0 0 14px;
			}
			&:hover:before { 
				border-color: transparent #333333 transparent transparent;
				border-width: 31px 14px 0 0;
			}			
		}

		// Active state, and its :hover to override normal :hover
		&.active > a {
			background-color: #333333!important;
			&,
			&:hover,
			&:focus {
				color: #ffffff;
				cursor: default;
				border-color: #222222;
			}
			&:after {
				border-color: transparent transparent transparent #333333;
			}
			&:before {
				border-color: transparent #333333 transparent transparent;
			}
		}
	}

	> li:first-child >a:before,
	> li:last-child >a:after {
		border-width: 0 0 0 0;
		border-color: transparent transparent transparent transparent;
	}

}
.nav-tabs.style-1 + .tab-content > .tab-pane {
	padding-bottom: 0;
	border-bottom: none;
}
.nav-tabs.style-2 {
	border-bottom: 1px solid #f1f1f1;
	> li {
		// Actual tabs (as links)
		> a {
			color: @gray-light;
			&:hover {
				color: @gray-dark;
				background-color: #fafafa!important;
				border-color: #f1f1f1;
				border-bottom-color: #fafafa!important;
			}			
			&:focus {
				border-color: transparent;
				background-color: transparent;
			}		
		}

		// Active state, and its :hover to override normal :hover
		&.active > a {
			&,
			&:hover,
			&:focus {
				color: @gray-dark;
				cursor: default;
				background-color: #fafafa;
				border-color: #f1f1f1;
				border-bottom-color: transparent;
			}
		}
	}
}
.nav-tabs.style-2 + .tab-content > .tab-pane {
	padding: 15px;
	border: 1px solid #f1f1f1;
	border-top: none;
	background-color: #fafafa;
}
.nav-tabs.style-3 {
	border-bottom: 1px solid @gray-light;
	background-color: @gray-light;
	> li {
		// Actual tabs (as links)
		> a {
			color: @white;
			.border-radius(0);
			margin: 0;
			&:hover {
				color: @gray-dark;
				background-color: #fff!important;
				border-color: @gray-light;
				border-bottom-color: #fff!important;
			}			
			&:focus {
				border-color: transparent;
				background-color: transparent;
			}		
		}
		// Active state, and its :hover to override normal :hover
		&.active > a {
			&,
			&:hover,
			&:focus {
				color: @gray-dark;
				cursor: default;
				background-color: #fff;
				border-color: @gray-light;
				border-bottom-color: transparent;
			}
		}
	}
}
.nav-tabs.style-3 + .tab-content > .tab-pane {
	padding: 15px;
	border: 1px solid @gray-light;
	border-top: none;
	background-color: #ffffff;
}
.nav-tabs.style-4 {
	margin-bottom: 15px;
	border-bottom: 1px solid @gray-dark;
	> li {
		// Actual tabs (as links)
		> a {
			color: @gray-light;
			&:hover {
				color: @white;
				background-color: @gray-dark!important;
				border-color: @gray-dark;
				border-bottom-color: @gray-dark!important;
			}			
			&:focus {
				border-color: @gray-dark;
				background-color: @gray-dark;
			}		
		}
		// Active state, and its :hover to override normal :hover
		&.active > a {
			&,
			&:hover,
			&:focus {
				color: @white;
				cursor: default;
				background-color: @gray-dark;
				border-color: @gray-dark;
				border-bottom-color: transparent;
			}
		}
	}
}
/* Small devices (tablets, smartphones) */
@media (min-width:481px) and (max-width:767px) {
	.nav-tabs > li > a {
		padding: 12px 10px;
		font-size: 11px;
		margin-right: 0;
	}
}

/* Extra small devices (phones, less than 480px) */
@media (max-width:480px) {
	.nav-tabs {
		background-color: #eaeaea;
		border-bottom: none!important;
	}
	.nav-tabs > li {
		float: none;
	}
	.nav-tabs > li a {
		color: #ffffff;
		margin-right: 0;
		-webkit-border-radius: 0px!important; 
		-moz-border-radius: 0px!important; 
		border-radius: 0px!important;
	}
	.nav-tabs.style-3 > li.active > a, 
	.nav-tabs.style-3 > li.active > a:hover, 
	.nav-tabs.style-3 > li.active > a:focus, 
	.nav-tabs.style-3 > li > a:hover, 
	.nav-tabs.style-3 > li > a:focus {
		border-bottom: 1px solid #999999!important;
	}
	.nav-tabs.style-3 + .tab-content > .tab-pane {
		border-top: 1px solid #999999;
	}
	.nav-tabs > li a:after,
	.nav-tabs > li a:before {
		border-color: transparent transparent transparent transparent!important;
	}
}

/* vertical tabs */
.vertical {
	margin: 20px 0;
}
.vertical .nav-tabs,
.vertical .tab-content {
	display: table-cell;
	margin: 0;
	vertical-align: top
}
.vertical .nav-tabs {
	border-bottom-color: transparent;
	background-color: #999999;
}
.vertical .nav-tabs > li {
	float: none;
}
.vertical .nav-tabs > li a {
	margin: 0;
	padding: 10px 20px;
	white-space: nowrap;
	color: #ffffff;
	border-bottom: 1px solid #919191;
	.border-radius(0);
}
.vertical .nav-tabs > li > a:hover,
.vertical .nav-tabs > li > a:focus {
	background-color: #fafafa;
	border-right-color: transparent;
	border-bottom: 1px solid #f1f1f1;
	color: @gray;
	z-index: 2;
}
.vertical .nav-tabs > li.active > a,
.vertical .nav-tabs > li.active > a:hover,
.vertical .nav-tabs > li.active > a:focus {
	border: 1px solid #f1f1f1;
	background-color: #fafafa;
	border-right-color: transparent;
	color: @gray;
	z-index: 2;
}
.vertical.left .nav-tabs > li > a:hover,
.vertical.left .nav-tabs > li > a:focus {
	border-left-color: transparent;
	border-right-color: #f1f1f1;
}
.vertical.left .nav-tabs > li.active > a,
.vertical.left .nav-tabs > li.active > a:hover,
.vertical.left .nav-tabs > li.active > a:focus {
	border-right-color: #f1f1f1;
	border-left-color: transparent;
}
.vertical .tab-content {
	border: 1px solid #f1f1f1;
	border-left: 1px solid #fafafa;
	background-color: #fafafa;
	left: -1px;
	z-index: 1;
	padding: 20px 30px;
}
.left.vertical .tab-content { 
	border-left-color: #f1f1f1;
	border-right-color: transparent;
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width:767px) {
	.vertical .nav-tabs,
	.vertical .tab-content {
		display: block;
	}
	.vertical .nav-tabs > li a { 
		font-size: 15px;
	}
}
/* Pager
---------------------------------- */
ul.pagination,
ul.pager {
	margin: 20px 0 40px;
	text-align: left;
}
ul.pagination li {
	padding: 0;
	margin: 0 5px 0 0;
	border-bottom: none;
	display: inline-block;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
}
ul.pagination li > a,
.pagination > li:first-child > a,
.pagination > li:last-child > a,
ul.pager li > a {
	text-align: center;
	padding: 0;
	height: 40px;
	width: 40px;
	border: 1px solid @gray;
	line-height: 39px;
	background-color: @gray;
	color: #ffffff;
	display: inline-block;
	.border-radius(100%);
}
ul.pagination li > a:hover,
ul.pagination li > a:focus {
	background-color: transparent;
	color: #ffffff;
	background-color: @base-color-hover;
	border-color: @base-color-hover;
}
ul.pagination li.active a,
.pagination > .active > a:hover,
.pagination > .active > a:focus {
	color: #ffffff;
	background-color: @base-color;
	border-color: @base-color;
}
/* Breadcrumb
---------------------------------- */
.breadcrumb-container {
	background-color: #f8f8f8;
	border-bottom: 1px solid #f4f4f4;
}
.dark.breadcrumb-container {
	background-color: @gray;
	border-color: @gray-dark;
	color: #ffffff;
}
.breadcrumb {
	background-color: transparent;
	.border-radius(0);
	margin-bottom: 0;
	font-size: 12px;
	padding-left: 0;
}
.breadcrumb > li + li:before {
	font-family: "FontAwesome";
	content: "\f101";
	font-size: 11px;
	padding-left: 3px;
}
.banner .breadcrumb-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background-color: rgba(255,255,255,0.85);
	border-bottom-color: rgba(255,255,255,0.1);
}
.banner .dark.breadcrumb-container {
	background-color: @dark-header-opacity-60;
	border-bottom-color: rgba(119,119,119,0.1);
}
.banner .breadcrumb > li a,
.banner .breadcrumb > li i {
	color: #000000;
}
.banner .breadcrumb > .active,
.banner .breadcrumb > li + li:before {
	color: @gray;
}
.dark .breadcrumb > li a,
.dark .breadcrumb > li i,
.dark .breadcrumb > .active,
.dark .breadcrumb > li + li:before {
	color: #ffffff;
}
.banner .breadcrumb > li a:hover {
	color: @base-color-hover;
}
/* Nav pills
---------------------------------- */
.nav-pills:not(.nav-stacked) {
	margin-bottom: 15px;
}
.text-center>.nav-pills {
	display: inline-block;
}
.nav-pills > li > a {
	border-radius: 0;
	padding: 7px 10px;
	border: 1px solid transparent;
	color: @gray-light;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 400;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
	color: @gray;
	background-color: transparent;
	border-bottom: 1px solid @gray;
}
.nav-pills.style-2 > li > a { 
	.border-radius(5px);
}
.nav-pills.style-2 > li.active > a,
.nav-pills.style-2 > li.active > a:hover,
.nav-pills.style-2 > li.active > a:focus,
.nav-pills.style-2 > li > a:hover,
.nav-pills.style-2 > li > a:focus {
	color: @base-color;
	background-color: #fafafa;
	border: 1px solid #eaeaea;
}
.nav-pills > li + li {
	margin-left: 5px;
}
.nav-pills.nav-stacked > li + li {
	margin-left: 0;
}
.nav-pills.nav-stacked > li > a { 
	padding: 5px 0px;
	color: @gray;
}
.nav-pills.nav-stacked.list-style-icons > li > a {
	padding: 4px 0px;
}
.nav-pills.nav-stacked > li.active > a,
.nav-pills.nav-stacked > li.active > a:hover,
.nav-pills.nav-stacked > li.active > a:focus,
.nav-pills.nav-stacked > li > a:hover,
.nav-pills.nav-stacked > li > a:focus {
	color: @base-color;
	background-color: transparent;
	border-color: transparent;
}
.nav-pills.nav-stacked:not(.list-style-icons) > li > a:after {
	font-family: "FontAwesome";
	content: "\f101";
	font-size: 12px;
	position: absolute;
	right: 15px;
	top: 6px;
	color: #d1d1d1;
	.transition(all 0.2s ease-in-out);
}
.nav-pills > li.active > a:after {
	color: @base-color;
}
.nav-pills.nav-stacked:not(.list-style-icons) > li.active > a:hover:after,
.nav-pills.nav-stacked:not(.list-style-icons) > li.active > a:focus:after,
.nav-pills.nav-stacked:not(.list-style-icons) > li > a:hover:after,
.nav-pills.nav-stacked:not(.list-style-icons) > li > a:focus:after {
	color: @base-color-hover;
	right: 10px;
}
.nav-pills.nav-stacked.list-style-icons > li > a > i {
	padding-right: 10px;
	font-size: 14px;
	color: @gray-lighter;
}
.nav-pills.nav-stacked.list-style-icons > li.active > a > i,
.nav-pills.nav-stacked.list-style-icons > li > a:hover > i,
.nav-pills.nav-stacked.list-style-icons > li > a:focus > i {
	color: @base-color-hover;
}
/* Process pills */
.process .nav-pills > li + li {
	margin-left: 40px;
	position: relative;
}
.process .nav-pills > li + li:after {
	font-family: "FontAwesome";
	content: "\f101";
	position: absolute;
	top: 6px;
	left: -24px;
	width: 10px;
	color: @gray-lighter;
}
/*footer*/
.footer-content .nav-pills > li > a {
	padding: 4px 0px;
}
.footer-content .nav-pills.nav-stacked > li > a:after {
	top: 5px;
}
.dark .footer-content .nav-pills > li > a,
.dark .footer-content .nav-pills > li.active > a,
.dark .footer-content .nav-pills > li.active > a:focus,
.dark .footer-content .nav-pills > li > a:focus {
	color: @gray-light;
}
.dark .footer-content .nav-pills > li.active > a:hover,
.dark .footer-content .nav-pills > li > a:hover {
	color: @base-color-hover;
}

/* Collapse
---------------------------------- */
.panel-group .panel-default {
	border-color: #eaeaea;
	.box-shadow(none);
}
.panel-group .panel-heading {
	padding: 0;
}
.panel-heading a {
	font-weight: 400;
	display: inline-block;
	width: 100%;
	position: relative;
	text-decoration: none;
	padding: 10px 40px 10px 15px;
	color: inherit;
}
/*Style 1*/
.collapse-style-1 .panel-heading {
	background-color: #fafafa;
}
.collapse-style-1 .panel-heading a {
	color: @gray-light;
}
.collapse-style-1 .panel-heading a:not(.collapsed) {
	color: @gray;
}
.collapse-style-1 .panel-heading a:after {
	color: #0DA9AA;
	font-family: "FontAwesome";
	content: "\f146";
	position: absolute;
	right: 15px;
	font-size: 14px;
	font-weight: 300;
	top: 50%;
	line-height: 1;
	margin-top: -7px;
}
.collapse-style-1 .panel-heading a.collapsed:after {
	content: "\f0fe";
}
.panel-heading a:hover {
	text-decoration: none;
}
.panel-title a i {
	font-size: 16px;
}
.collapse-style-1 .panel-body {
	border-top: none!important;
	background-color: #fafafa;
	padding-top: 0;
}
/*Style 2*/
.collapse-style-2 .panel {
	border-color: transparent;
}
.collapse-style-2 .panel-heading {
	background-color: transparent;
}
.collapse-style-2 .panel-heading a {
	color: @white;
	background-color: @base-color;
}
.collapse-style-2 .panel-heading a.collapsed {
	background-color: @gray-dark;
}
.collapse-style-2 .panel-heading a:after {
	font-family: "FontAwesome";
	content: "\f106";
	position: absolute;
	right: 15px;
	font-size: 14px;
	font-weight: 300;
	top: 50%;
	line-height: 1;
	margin-top: -7px;
}
.collapse-style-2 .panel-heading a.collapsed:after {
	content: "\f107";
}
.panel-heading a:hover {
	text-decoration: none;
}
.panel-title a i {
	font-size: 16px;
}
.collapse-style-2 .panel-body {
	border-top: none!important;
	background-color: transparent;
	padding: 15px 0;
}
.collapse-style-2 .panel-body.p-15 { 
	padding: 15px;
}
/*Style 3*/
.collapse-style-3 .panel {
	border-color: @gray-light;
}
.collapse-style-3 .panel-heading {
	background-color: transparent;
}
.collapse-style-3 .panel-heading a {
	color: @white;
	background-color: @gray-light;
}
.collapse-style-3 .panel-heading a.collapsed {
	background-color: @gray-light;
}
.collapse-style-3 .panel-heading a:after {
	font-family: "FontAwesome";
	content: "\f139";
	position: absolute;
	right: 15px;
	font-size: 12px;
	font-weight: 300;
	top: 50%;
	line-height: 1;
	margin-top: -7px;
}
.collapse-style-3 .panel-heading a.collapsed:after {
	content: "\f13a";
}
.panel-heading a:hover {
	text-decoration: none;
}
.panel-title a i {
	font-size: 16px;
}
.collapse-style-3 .panel-body {
	border-top: none!important;
	background-color: transparent;
}
/*Contextual Headings*/
.collapse-contextual .panel-heading a:after {
	font-family: "FontAwesome";
	content: "\f068";
	position: absolute;
	right: 15px;
	font-size: 10px;
	font-weight: 300;
	top: 50%;
	line-height: 1;
	margin-top: -6px;
}
.collapse-contextual .panel-heading a.collapsed:after {
	content: "\f067";
}
/*Content Collapse*/
.btn[data-toggle="collapse"] i:before {
	font-size: 10px;
	content: "\f068";
}
.btn.collapsed[data-toggle="collapse"] i:before {
	content: "\f067";
}
/* Progress bars
---------------------------------- */
.progress.style-1 {
	margin-top: 25px;
	background-color: #fafafa;
	border: 1px solid #eaeaea;
	height: 8px;
	.border-radius(4px);
	.box-shadow(none);
	overflow: visible;
	position: relative;
	
	.progress-bar {
		padding: 0;
		text-align: left;
		font-size: 11px;
		font-weight: 300;
		margin-top: 0px;
		height: 4px;
		margin: 1px;
		.border-radius(5px);
		.box-shadow(none);
		position: relative;
	}
}
.progress.style-2 {
	margin-bottom: 15px;
	background-color: #fafafa;
	border: 1px solid #eaeaea;
	height: 20px;
	.box-shadow(none);

	.progress-bar {
		padding: 0;
		text-align: left;
		.box-shadow(none);
	}
	span {
		font-size: 11px;
		line-height: 1;
		position: relative;
		top: -2px;
		left: 10px;
	}
}
.progress-bar {
	.transition(width 1.5s ease);
}
.progress .label {
	position: absolute;
	top: -16px;
	font-size: 11px;
	color: @gray-dark;
	right: 10px;
	font-weight: 400;
	padding: 0;
}
.progress .text {
	position: absolute;
	font-size: 11px;
	top: -17px;
	left: 0;
	font-weight: 400;
	text-transform: uppercase;
	color: @gray-light;
}
.progress.dark {
	background-color: @gray-lighter;
	border-color: @gray-lighter;
}
.progress.dark.style-1 {
	border-color: @white;
}
.dark .progress-striped .progress-bar, 
.dark .progress-bar-striped {
	background-image: -webkit-linear-gradient(45deg, rgba(0, 0, 0, .05) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .05) 50%, rgba(0, 0, 0, .05) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(0, 0, 0, .05) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .05) 50%, rgba(0, 0, 0, .05) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(0, 0, 0, .05) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .05) 50%, rgba(0, 0, 0, .05) 75%, transparent 75%, transparent);
}
.progress-bar-white {
	background-color: @white;
	color: @gray;
}
.progress-bar-default {
	background-color: @base-color;
}
.progress-bar-gray {
	background-color: #999999;
}
.progress-bar-dark {
	background-color: #333333;
}
.gray-bg .progress {
	background-color: #ffffff;
	-webkit-box-shadow: inset 0 2px 2px rgba(0, 0, 0, .1);
	box-shadow: inset 0 2px 2px rgba(0, 0, 0, .1);
}

/* Circular Progress Bars
---------------------------------- */
.knob-container {
	margin: 10px;
	position: relative;
	display: inline-block;
}
.knob-container canvas {
	width: 132px;
	height: 132px;
	margin: 0 auto;
}
.knob-text {
	position: absolute;
	text-align: center;
	top: 50%;
	width: 100%;
	margin-top: -14px;
}

/* Large devices (Large desktops 1200px and up) */
@media (min-width:1200px) {
	.knob-container canvas {
		width: 169px;
		height: 169px;
	}
}

/* Forms
---------------------------------- */
form {
	margin: 20px 0;
}
textarea {
	resize: vertical;
}
.sidebar form {
	margin: 5px 0 20px 0;
}
.form-control {
	.border-radius(5px);
	background: #ffffff;
	height: 40px;
	padding-right: 30px;
	border-color: #e1e1e1;
	color: @gray;
	position: relative;
	-webkit-appearance: none;
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
}
.form-control-lg { 
	height: 48px;
}
/* Small devices (tablets, phones 481px and up) */
@media (min-width: 481px) { 
	.form-control-lg { 
		min-width: 350px;
	}
}
.form-control:focus {
	border-color: @gray-light;
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
}
select.form-control {
	padding-right: 5px;
	-webkit-appearance: menulist;
}
select[multiple].form-control {
	-webkit-appearance: listbox;
}
.form-control::-moz-placeholder {
	color: inherit;
}
.form-control:-ms-input-placeholder {
	color: inherit;
}
.form-control::-webkit-input-placeholder {
	color: inherit;
}
.input-group-addon {
	background-color: #f1f1f1;
	border-color: #e1e1e1;
}
.form-list > div {
	padding: 7px 15px;
	border-bottom: 1px solid #dddddd;
	background-color: #f3f3f3;
	margin: 6px 0;
	display: block;
}
form label,
.radio label, 
.checkbox label {
	font-weight: 300;
}
fieldset {
	padding: 10px 25px;
	margin: 15px 0px;
	border: 1px solid #dddddd;
	background-color: #fafafa;
}
fieldset legend {
	display: block;
	width: auto;
	padding: 3px 10px;
	margin-bottom: 0;
	font-size: 15px;
	line-height: inherit;
	color: #333;
	border: 1px solid #ddd;
	background-color: #ffffff;
}
fieldset .form-control,
fieldset .form-control:focus {
	webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, .12);
	box-shadow: inset 0 2px 3px rgba(0, 0, 0, .12);
	border-color: #f3f3f3;
}
input[type="radio"], 
input[type="checkbox"] {
	margin-top: 5px;
}
/* forms with icons */
.has-feedback label ~ .form-control-feedback {
	top: 27px;
}
.form-control-feedback {
	width: 40px;
	height: 40px;
	line-height: 42px !important;
	top: 0;
	color: @gray;
}
.form-control-lg + .form-control-feedback,
.has-feedback label.sr-only ~ .form-control-lg + .form-control-feedback {
	height: 48px;
	top: 4px;
}
/* Disabled forms */
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	opacity: 0.5;
	filter: alpha(opacity=50);
}
/* forms with icons */
.form-control-feedback {
	width: 40px;
	height: 40px;
	line-height: 40px !important;
	top: 0;
}
/* Forms in all backgrounds */
.dark-bg {
	.form-control {
		background-color: rgba(255, 255, 255, 0.15);
		border-color: #353535;
		.box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.8));
		color: @gray-lighter;
	}
	.has-success .form-control:focus,
	.has-warning .form-control:focus,
	.has-error .form-control:focus {
		.box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.8));
	}
	.input-group-addon {
		background-color: rgba(255, 255, 255, 0.20);
		border-color: #000;
	}
	.form-control:focus,
	.has-success .form-control:focus,
	.has-warning .form-control:focus,
	.has-error .form-control:focus {
		background-color: rgba(255, 255, 255, 0.25);
	}
	.form-control-feedback {
		color: @gray-lighter;
	}
}
.default-bg {
	.form-control {
		background-color: rgba(0, 0, 0, 0.2);
		border-color: @base-color-hover;
		.box-shadow(inset 0 2px 3px rgba(0, 0, 0, .35));
		color: @white;
	}
	.has-success .form-control:focus,
	.has-warning .form-control:focus,
	.has-error .form-control:focus {
		.box-shadow(inset 0 2px 3px rgba(0, 0, 0, .35));
	}
	.input-group-addon {
		background-color: rgba(0, 0, 0, 0.20);
		border-color: @base-color-hover;
	}
	.form-control:focus {
		background-color: rgba(0, 0, 0, 0.1);
	}
	.form-control-feedback {
		color: @white;
	}
}
.dark {
	.form-control {
		.box-shadow(inset 0 2px 3px rgba(0, 0, 0, 0.6));
		color: #cccccc;
		background-color: rgba(255, 255, 255, 0.10);
		border-color: #373737;
		&:focus{
			background-color: rgba(255, 255, 255, 0.15);
		}
	}
	.form-control-feedback { 
		color: @gray-lighter;
	}
}
.dark .footer-content .form-control { 
	border-color: #222222;
}
/* Validation states */
.dark-bg {
	.has-success .help-block, 
	.has-success .control-label, 
	.has-success .radio, 
	.has-success .checkbox, 
	.has-success .radio-inline, 
	.has-success .checkbox-inline, 
	.has-success.radio label, 
	.has-success.checkbox label, 
	.has-success.radio-inline label, 
	.has-success.checkbox-inline label,
	.has-success .form-control-feedback {
		color: #15c019;
	}
	.has-warning .help-block, 
	.has-warning .control-label, 
	.has-warning .radio, 
	.has-warning .checkbox, 
	.has-warning .radio-inline, 
	.has-warning .checkbox-inline, 
	.has-warning.radio label, 
	.has-warning.checkbox label, 
	.has-warning.radio-inline label, 
	.has-warning.checkbox-inline label,
	.has-warning .form-control-feedback {
		color: #e5af51;
	}
	.has-error .help-block, 
	.has-error .control-label, 
	.has-error .radio, 
	.has-error .checkbox, 
	.has-error .radio-inline, 
	.has-error .checkbox-inline, 
	.has-error.radio label, 
	.has-error.checkbox label, 
	.has-error.radio-inline label, 
	.has-error.checkbox-inline label,
	.has-error .form-control-feedback {
		color: #ef4f4c;
	}
}
.has-success .form-control,
.has-success .form-control:focus {
	border-color: #3c763d;
}
.has-warning .form-control,
.has-warning .form-control:focus {
	border-color: #8a6d3b;
}
.has-error .form-control,
.has-error .form-control:focus {
	border-color: #a94442;
}
.default-bg .has-success .form-control,
.default-bg .has-warning .form-control,
.default-bg .has-error .form-control,
.default-bg .has-success .form-control:focus,
.default-bg .has-warning .form-control:focus,
.default-bg .has-error .form-control:focus {
	border-color: #fff;
	color: #ffffff;
}
.default-bg .has-success .form-control-feedback,
.default-bg .has-warning .form-control-feedback,
.default-bg .has-error .form-control-feedback,
.default-bg .has-success .control-label,
.default-bg .has-warning .control-label,
.default-bg .has-error .control-label {
	color: #fff;
}
.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-error .form-control:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.has-error .error {
	font-weight: 400;
	margin-bottom: 5px;
	display: block;
}
/*Sorting Filters*/
.sorting-filters {
	label {
		display: block;
		margin-bottom: 5px;
		font-size: 14px;
		text-align: left;
	}
	form {
		margin: 0;
	}
	.form-inline .form-control {
		width: 100%;
	}
	.btn {
		margin: 0;
		min-width: 0;
		width: 100%;
	}
}
/* Small devices (tablets, smartphones) */
@media (min-width: 768px) and (max-width: 991px) { 
	.sorting-filters .form-inline .form-group {
		width: 18%;
		margin-right: 1.2%;
	}
}
/* Small devices (tablets, smartphones 768px and up) */
@media (min-width: 768px) { 
	.sorting-filters .btn {
		margin: 25px 0 0;
	}
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) { 
	.sorting-filters .form-control {
		margin: 0 0 5px;
	}
}
/* Alerts
---------------------------------- */
.alert {
	margin: 20px 0;
	.border-radius(5px);
	border: none;
}
.alert-success {
	color: #2a5f2b;
	background-color: #bcdab0;
}
.alert-info {
	color: #1a4e68;
	background-color: #c6e8fa;
}
.alert-warning {
	color: #72582b;
	background-color: #f7f0ce;
}
.alert-icon { 
	padding-left: 70px;
	position: relative;
}
.alert-icon i { 
  position: absolute;
  font-size: 28px;
  left: 21px;
  top: 13px;
  width: 30px;
  text-align: center;
}
.close {
	font-size: 28px;
	font-weight: normal;
	filter: alpha(opacity=40);
	opacity: 0.4;
}
[data-notify="container"] {
	max-width: 400px;
}
[data-notify="progressbar"] {
	display: none;
}

/*Icons
---------------------------------- */
.icon {
	display: inline-block;
	text-align: center;
	position: relative;
	background-color: rgba(255, 255, 255, 0.85);
	color: #000;
	font-size: 24px;
	width: 60px;
	height: 60px;
	line-height: 58px;
	.transition(all 0.2s ease-in-out);
	.border-radius(5px);
	border: 1px solid transparent;
}
.icons .icon {
	margin: 0 5px 5px 0;
}
.icon i {
	position: relative;
	z-index: 1;
}
.icon.dark-bg {
	background-color: rgba(66, 66, 66, 0.66);
	color: #ffffff;
}
.icon.default-bg {
	background-color: @base-color;
	color: #ffffff;
}
.icon.light-bg {
	background-color: #fff;
	color: @base-color;
}
.icon.without-bg {
	font-size: 36px;
	line-height: 1;
	color: inherit;
	background-color: transparent;
	height: 100%;
}
.icon.large { 
	font-size: 36px;
	width: 100px;
	height: 100px;
	line-height: 97px;
}
.icon.small { 
	font-size: 18px;
	width: 45px;
	height: 45px;
	line-height: 43px;
}
/*Feature Box
---------------------------------- */
.feature-box,
.feature-box-2 {
	margin-bottom: 30px;
}
.feature-box .title,
.feature-box-2 .title {
	margin-top: 0px;
}
/*style-1*/
.feature-box h3 {
	margin-top: 0;
}
.feature-box .icon {
	margin-bottom: 10px;
}
.feature-box-2 i {
	color: @base-color;
}
.feature-box p {
	clear: both;
	margin-bottom: 15px;
}
.feature-box:not(.shadow):hover .icon {
	.opacity(0.8);
}
/*style-2*/
.feature-box-2 {
	position: relative;
}
.feature-box-2 .icon {
	position: absolute;
	top: 0px;
	left: 0px;
}
.feature-box-2 .icon.without-bg {
	width: 0;
	height: 0;
}
.feature-box-2.boxed {
	border: 1px solid #f1f1f1;
}
.feature-box-2.boxed .icon { 
	left: 20px;
	top: 20px;
}
.feature-box-2 .icon {
	.transition(all 0.2s ease-in-out);
}
.feature-box-2 .body {
	margin-left: 10px;
}
.feature-box-2:hover .icon {
	.opacity(0.8);
}
.feature-box-2.right .body {
	margin-right: 10px;
	margin-left: 0px;
	text-align: right;
}
.feature-box-2.right .icon {
	left: auto;
	right: 0px;
}
.feature-box-2.right .icon.without-bg {
	right: 40px;
}
.feature-box-2.boxed.right .icon { 
	left: auto;
	right: 20px;
}
.feature-box-2.boxed.right .icon.without-bg { 
	right: 60px;
}
/* Medium devices (desktops, tablets less than 991px) */
@media (max-width: 991px) { 
	.feature-box-2:not(.boxed) { 
		padding-bottom: 0;
	}
}
/*shadow*/
.shadow,
.shadow-narrow { 
	position: relative;
	background-color: #ffffff;	
}
.light-gray-bg.shadow,
.light-gray-bg.shadow-narrow { 
	background-color: #fafafa;
}
.bordered {
	border: 1px solid #f1f1f1;
	.border-radius(3px);
}
.dark-bg .bordered {
	border: 1px solid #555555;
}
.shadow *,
.shadow-narrow * {
	position: relative;
	z-index: 3;
}
.shadow:before,
.shadow-narrow:before {
	position: absolute;
	left: 0;
	height: 60%;
	bottom: 0;
	width: 100%;
	content: "";
	background-color: #ffffff;
	z-index: 2;
}
.light-gray-bg.shadow:before,
.light-gray-bg.shadow-narrow:before {
	background-color: #fafafa;
}
.shadow:after,
.shadow-narrow:after {
	content: "";
	position: absolute;
	height: 50%;
	width: 96%;
	left: 50%;
	bottom: 2px;
	margin-left: -48%;
	.box-shadow(0 5px 7px #999);
	z-index: 1;
	border-radius: 10%;
	.transition(all 0.3s ease-in-out);
}
.shadow-narrow:after {
	width: 90%;
	margin-left: -45%;
}
.shadow:hover:after,
.shadow-narrow:hover:after {

}
/*Image box
---------------------------------- */
.owl-item .image-box {
	margin-bottom: 20px;
}
.image-box .body {
	padding: 15px;
	h3 {
		margin-top: 0;
	}
	p:last-child {
		margin-bottom: 0;
	}
}
.image-box.style-4 { 
	border: none;
}
.image-box.style-3-b { 
	display: block;
	margin-bottom: 40px;
	.body {
		padding: 10px 0;
	}
}

/* Medium devices (tablets, laptops, smartphones 768px and up) */
@media (min-width: 768px) { 
	.image-box.style-3-b .body {
		padding: 0;
	}
}

/* Medium devices (desktops, tablets less than 991px) */
@media (max-width: 991px) { 
	.image-box.style-4 {
		margin-bottom: 30px;
	}
}
/*Listing Items
---------------------------------- */
.listing-item {
	.overlay-container .badge {
		position: absolute;
		border: 1px solid @base-color;
		background-color: rgba(255, 255, 255, 0.95);
		color: @base-color;
		font-weight: 400;
		padding: 4px;
		top: 10px;
		right: 10px;
		.border-radius(2px);
	}
	.body {
		padding: 15px;
		h3 {
			margin-top: 0;
			font-size: 18px;
			line-height: 1.4;
		}
	}
	.elements-list {
		font-size: 12px;
	}
	.price {
		font-size: 18px;
		margin-top: 1px;
		display: inline-block;
		font-weight: 400;
		del {
			font-size: 12px;
			color: #999999;
		}
	}
	.overlay-to-top {
		padding: 10px 0!important;
		.btn-sm-link {
			padding: 5px;
		}
	}
}
/*Modals
---------------------------------- */
.modal-content {
	.modal-header {
		background-color: @base-color;
	}
	.modal-title {
		color: #ffffff;
	}
}
.modal-open .page-wrapper {
  -webkit-filter: blur(3px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
.modal-footer .btn + .btn {
	margin-bottom: 5px;
}
/* Large devices (Large desktops 1200px and up) */
@media (min-width:1200px) {
	.modal-lg {
		width: 1140px;
	}
}
/*Images and Overlays
---------------------------------- */
.overlay-container {
	position: relative;
	display: block;
	text-align: center;
	overflow: hidden;
}
.overlay-bottom,
.overlay-top {
	position: absolute;
	top: auto;
	padding: 15px;
	bottom: 0px;
	left: 0;
	right: 0px;
	background-color: rgba(30, 30, 30, 0.50);
	overflow: hidden;
	.opacity(0);
	.transition(all ease-in-out 0.25s);
	height: 0;
	color: #ffffff;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0) scale(1.0, 1.0);
}
.overlay-bottom.links,
.overlay-to-top.links {
	z-index: 11;
}
.overlay-to-top {
	position: absolute;
	top: auto;
	padding: 10px;
	bottom: -30px;
	left: 0;
	right: 0px;
	background-color: rgba(30, 30, 30, 0.50);
	.opacity(0);
	.transition(all ease-in-out 0.25s);
	color: #ffffff;
}
.overlay-top {
	top: 0;
}
.overlay-visible .overlay-bottom,
.overlay-visible .overlay-top {
	.opacity(1);
	height: auto!important;
	padding-bottom: 20px
}
.overlay-visible.overlay-container:hover .overlay-bottom:not(.links),
.overlay-visible.overlay-container:hover .overlay-top {
	.opacity(0.5);
}
.overlay-top a,
.overlay-bottom a,
.overlay-to-top a { 
	color: #ffffff;
}
.overlay-container .text {
	padding: 0 20px;
	position: relative;
}
.overlay-container .text h3,
.overlay-container .text h3 .small {
	margin-top: 0;
	color: #ffffff;
}
.overlay-container .text a:hover {
	color: #ffffff;
}
.overlay-bottom .btn,
.overlay-top .btn {
	color: #ffffff;
	border-color: #ffffff;
	margin: 0;
}
.overlay-bottom .btn:hover,
.overlay-top .btn:hover { 
	color: @gray;
	background-color: #ffffff;
}
.overlay-container:hover .overlay-top,
.overlay-container:hover .overlay-bottom,
.image-box.style-2:hover .overlay-top,
.image-box.style-2:hover .overlay-bottom {
	height: 50%;
	.opacity(1);
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0) scale(1.0, 1.0);
}
.overlay-container:hover .overlay-to-top,
.image-box:hover .overlay-to-top {
	.opacity(1);
	bottom: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0) scale(1.0, 1.0);
}
.overlay-link {
	position: absolute;
	z-index: 10;
	top: 0;
	padding: 15px;
	bottom: 0px;
	left: 0;
	right: 0px;
	background-color: rgba(30, 30, 30, 0.50);
	overflow: hidden;
	.opacity(0);	
	.transition(all ease-in-out 0.25s);
	color: #ffffff;
}
.overlay-link i {
	.transition(all 0.2s ease-in-out);
	width: 80px;
	height: 80px;
	border: 1px solid #ffffff;
	.border-radius(100%);
	color: #ffffff;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -40px 0 0 -40px;
	font-size: 32px;
	line-height: 80px;
}
.overlay-link i:hover {
	background-color: #ffffff;
	color: @gray;
}
.overlay-link.small i {
	width: 25px;
	height: 25px;
	margin: -12.5px 0 0 -12.5px;
	font-size: 14px;
	line-height: 25px;	
}
.overlay-container:hover .overlay-link {
	height: 100%;
	.opacity(1);
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0) scale(1.0, 1.0);
}
/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) { 
	.overlay-container .text {
		top: 30%;
	}
}
/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) { 
	.overlay-container .text {
		top: 30%;
	}
	.overlay-container .text p:not(.mobile-visible) {
		display: none;
	}
}
/* Large and Medium devices (desktops, tablets less than 1199px) */
@media (min-width: 480px) and (max-width:1199px) { 
	.overlay-container:not(.overlay-visible) .overlay-top ~ .overlay-bottom {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.owl-item .overlay-container:hover .overlay-top {
		height: 71%;
	}
	.owl-item .overlay-container:hover .overlay-bottom {
		height: 29%;
	}
}
/*Video Backgrounds
---------------------------------- */
.video-background,
.video-background-banner {
	position: relative;
	overflow: hidden;
}
/* Responsive iframe
---------------------------------- */
.tp-caption .embed-responsive {
	overflow: visible;
}
/* Audio wrapper
---------------------------------- */
.audio-wrapper iframe {
	width: 100%;
	border: none;
	margin-bottom: 20px;
}
/* Jumbotron
---------------------------------- */
.jumbotron {
	background-color: transparent;
}
/*Carousel
---------------------------------- */
.top.carousel-indicators {
	bottom: auto;
	top: 10px;
}
.bottom.carousel-indicators {
	bottom: 10px;
}
.shadow .carousel-indicators {
	position: absolute;
	z-index: 4;
}
/*Badges
---------------------------------- */
.main-navigation .badge { 
    position: absolute;
    top: 13px;
    right: 20px;
    font-size: 12px;
    background-color: transparent;
    color: inherit;
    font-weight: 300;
    text-transform: uppercase;
    padding: 2px 4px;
}
.main-navigation .mega-menu .badge {
	top: 8px;
}
/*Pace (Page loader)
---------------------------------- */
/*loader 1*/
.page-loader-1 .page-wrapper { 
	.opacity(0);
}
.page-loader-1.pace-done .page-wrapper { 
	.opacity(1);
	.transition(ease-in-out opacity 0.2s); 
}
.page-loader-1 .pace {
	-webkit-pointer-events: none;
	pointer-events: none;

	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;

	z-index: 2000;
	position: fixed;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 5px;
	width: 200px;
	background: #fff;
	border: 1px solid @base-color;

	overflow: hidden;
}
.page-loader-1 .pace .pace-progress {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	max-width: 200px;
	position: fixed;
	z-index: 2000;
	display: block;
	position: absolute;
	top: 0;
	right: 100%;
	height: 100%;
	width: 100%;
	background: @base-color;
}
/*loader 2*/
.page-loader-2 .page-wrapper { 
	.opacity(0);
}
.page-loader-2.pace-done .page-wrapper { 
	.opacity(1);
	.transition(ease-in-out opacity 0.2s); 
}
.page-loader-2 .pace {
	-webkit-pointer-events: none;
	pointer-events: none;

	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;

	z-index: 2000;
	position: fixed;
	height: 60px;
	width: 100px;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.page-loader-2 .pace .pace-progress {
	z-index: 2000;
	position: absolute;
	height: 60px;
	width: 100px;

	-webkit-transform: translate3d(0, 0, 0) !important;
	-ms-transform: translate3d(0, 0, 0) !important;
	transform: translate3d(0, 0, 0) !important;
}
.page-loader-2 .pace .pace-progress:before {
	content: attr(data-progress-text);
	text-align: center;
	color: #fff;
	background: @base-color;
	border-radius: 50%;
	font-family: "Helvetica Neue", sans-serif;
	font-size: 14px;
	font-weight: 100;
	line-height: 45px;
	padding: 0;
	width: 44px;
	height: 44px;
	margin: 8px 0 0 29px;
	display: block;
	z-index: 999;
	position: absolute;
}
.page-loader-2 .pace .pace-activity {
	font-size: 15px;
	line-height: 1;
	z-index: 2000;
	position: absolute;
	height: 60px;
	width: 100px;

	display: block;
	-webkit-animation: pace-theme-center-atom-spin 2s linear infinite;
	-moz-animation: pace-theme-center-atom-spin 2s linear infinite;
	-o-animation: pace-theme-center-atom-spin 2s linear infinite;
	animation: pace-theme-center-atom-spin 2s linear infinite;
}
.page-loader-2 .pace .pace-activity {
	border-radius: 50%;
	border: 5px solid @base-color;
	content: ' ';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	height: 60px;
	width: 100px;
}
.page-loader-2 .pace .pace-activity:after {
	border-radius: 50%;
	border: 5px solid @base-color;
	content: ' ';
	display: block;
	position: absolute;
	top: -5px;
	left: -5px;
	height: 60px;
	width: 100px;

	-webkit-transform: rotate(60deg);
	-moz-transform: rotate(60deg);
	-o-transform: rotate(60deg);
	transform: rotate(60deg);
}
.page-loader-2 .pace .pace-activity:before {
	border-radius: 50%;
	border: 5px solid @base-color;
	content: ' ';
	display: block;
	position: absolute;
	top: -5px;
	left: -5px;
	height: 60px;
	width: 100px;

	-webkit-transform: rotate(120deg);
	-moz-transform: rotate(120deg);
	-o-transform: rotate(120deg);
	transform: rotate(120deg);
}
@-webkit-keyframes pace-theme-center-atom-spin {
	0%   { -webkit-transform: rotate(0deg) }
	100% { -webkit-transform: rotate(359deg) }
}
@-moz-keyframes pace-theme-center-atom-spin {
	0%   { -moz-transform: rotate(0deg) }
	100% { -moz-transform: rotate(359deg) }
}
@-o-keyframes pace-theme-center-atom-spin {
	0%   { -o-transform: rotate(0deg) }
	100% { -o-transform: rotate(359deg) }
}
@keyframes pace-theme-center-atom-spin {
	0%   { transform: rotate(0deg) }
	100% { transform: rotate(359deg) }
}
/*loader 3*/
.page-loader-3 .pace {
	-webkit-pointer-events: none;
	pointer-events: none;

	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.pace.pace-inactive {
	display: none;
}
.page-loader-3 .pace .pace-progress {
	background: @base-color;
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 2px;
}
/*loader 4*/
.page-loader-4 .page-wrapper { 
	.opacity(0);
}
.page-loader-4.pace-done .page-wrapper { 
	.opacity(1);
	.transition(ease-in-out opacity 0.2s); 
}
.page-loader-4 .pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  -webkit-perspective: 12rem;
  -moz-perspective: 12rem;
  -ms-perspective: 12rem;
  -o-perspective: 12rem;
  perspective: 12rem;

  z-index: 2000;
  position: fixed;
  height: 6rem;
  width: 6rem;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.page-loader-4 .pace .pace-progress {
  position: fixed;
  z-index: 2000;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 6rem;
  width: 6rem !important;
  line-height: 6rem;
  font-size: 2rem;
  border-radius: 50%;
  background: @base-color;
  color: #fff;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 100;
  text-align: center;

  -webkit-animation: pace-theme-center-circle-spin linear infinite 2s;
  -moz-animation: pace-theme-center-circle-spin linear infinite 2s;
  -ms-animation: pace-theme-center-circle-spin linear infinite 2s;
  -o-animation: pace-theme-center-circle-spin linear infinite 2s;
  animation: pace-theme-center-circle-spin linear infinite 2s;

  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.page-loader-4 .pace .pace-progress:after {
  content: attr(data-progress-text);
  display: block;
}
@-webkit-keyframes pace-theme-center-circle-spin {
  from { -webkit-transform: rotateY(0deg) }
  to { -webkit-transform: rotateY(360deg) }
}
@-moz-keyframes pace-theme-center-circle-spin {
  from { -moz-transform: rotateY(0deg) }
  to { -moz-transform: rotateY(360deg) }
}
@-ms-keyframes pace-theme-center-circle-spin {
  from { -ms-transform: rotateY(0deg) }
  to { -ms-transform: rotateY(360deg) }
}
@-o-keyframes pace-theme-center-circle-spin {
  from { -o-transform: rotateY(0deg) }
  to { -o-transform: rotateY(360deg) }
}
@keyframes pace-theme-center-circle-spin {
  from { transform: rotateY(0deg) }
  to { transform: rotateY(360deg) }
}
/*loader 5*/
.page-loader-5 .page-wrapper { 
	.opacity(0);
}
.page-loader-5.pace-done .page-wrapper { 
	.opacity(1);
	.transition(ease-in-out opacity 0.2s); 
}
.page-loader-5 .pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.page-loader-5 .pace .pace-progress {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  height: 5rem;
  width: 5rem;

  -webkit-transform: translate3d(0, 0, 0) !important;
  -ms-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}
.page-loader-5 .pace .pace-progress:after {
  display: block;
  position: absolute;
  top: 0;
  right: .5rem;
  content: attr(data-progress-text);
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 100;
  font-size: 100px;
  line-height: 1;
  text-align: right;
  color: @base-color;
}
/*loader 6*/
.page-loader-6 .page-wrapper { 
	.opacity(0);
}
.page-loader-6.pace-done .page-wrapper { 
	.opacity(1);
	.transition(ease-in-out opacity 0.2s); 
}
.page-loader-6 .pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.page-loader-6 .pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  width: 300px;
  height: 300px;
  background: @base-color;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: translateX(100%) translateY(-100%) rotate(45deg);
  transform: translateX(100%) translateY(-100%) rotate(45deg);
  pointer-events: none;
}
.page-loader-6 .pace.pace-active .pace-activity {
  -webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
  transform: translateX(50%) translateY(-50%) rotate(45deg);
}
.page-loader-6 .pace .pace-activity::before,
.page-loader-6 .pace .pace-activity::after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    bottom: 30px;
    left: 50%;
    display: block;
    border: 5px solid #fff;
    border-radius: 50%;
    content: '';
}
.page-loader-6 .pace .pace-activity::before {
    margin-left: -40px;
    width: 80px;
    height: 80px;
    border-right-color: rgba(0, 0, 0, .2);
    border-left-color: rgba(0, 0, 0, .2);
    -webkit-animation: pace-theme-corner-indicator-spin 3s linear infinite;
    animation: pace-theme-corner-indicator-spin 3s linear infinite;
}
.page-loader-6 .pace .pace-activity::after {
    bottom: 50px;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    border-top-color: rgba(0, 0, 0, .2);
    border-bottom-color: rgba(0, 0, 0, .2);
    -webkit-animation: pace-theme-corner-indicator-spin 1s linear infinite;
    animation: pace-theme-corner-indicator-spin 1s linear infinite;
}
@-webkit-keyframes pace-theme-corner-indicator-spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(359deg); }
}
@keyframes pace-theme-corner-indicator-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}