/* 8 Main Slideshow
----------------------------------------------------------------------------- */
.slider-banner-container {
	width: 100%;
	position: relative;
	padding: 0;
}
.slider-banner-fullscreen,
.slider-banner-fullwidth {
	width: 100%;
	position: relative;
}
.slider-banner-container ul.slides {
	display: none;
}
/*Spinner*/
.tp-loader.spinner2 {
	background-color: #999;
}
/*Captions*/
.tp-caption {
	z-index: 5;
}
.tp-caption a,
.tp-caption a:hover {
	color: #ffffff;
}
.tp-caption a.btn-gray,
.tp-caption a.btn-gray:hover {
	color: @gray-dark;
}
.caption-box { 
	max-width: 540px;
	color: #ffffff;
	white-space: normal;
	padding: 20px;
	border: none;
	h2 {
		color: #ffffff;
	}
}
.light-translucent-bg.caption-box { 
	h2,p {
		color: @gray-dark; font-weight: 400;
	}
}
.caption-box:after {
	z-index: -1;
}
.slideshow .dark-translucent-bg:not(.caption-box),
.slideshow .light-translucent-bg:not(.caption-box) {
	border-top: none;
	border-bottom: none;
	position: absolute;
	left: 0;
	top: 0!important;
	width: 100%;
	height: 100%;
}
.slideshow .dark-translucent-bg {
	background-color: rgba(0, 0, 0, 0.4);
}
.slideshow .light-translucent-bg {
	background-color: rgba(255, 255, 255, 0.4);
}
.slideshow .dark-translucent-bg:after,
.slideshow .light-translucent-bg:after {
	background-color: transparent;
}
.xlarge_white {
	font-size: 68px;
	font-family: @font-family-raleway;
	font-weight: 400;
	color: #ffffff;
	line-height: 1.2;
}
.large_white {
	font-size: 42px;
	font-family: @font-family-raleway;
	color: #ffffff;
	line-height: 1.2;
}
.medium_white {
	font-size: 24px;
	color: #ffffff;
	font-weight: 300;
}
.small_white {
	font-size: 16px;
	color: #ffffff;
	font-weight: 300;
}
.xlarge_dark {
	font-size: 68px;
	font-family: @font-family-raleway;
	font-weight: 400;
	color: #333;
	line-height: 1.2;
}
.large_dark {
	font-size: 42px;
	font-family: @font-family-raleway;
	color: #333;
	line-height: 1.2;
}
.tp-caption.medium_dark {
	font-family: @font-family-roboto;
	font-size: 24px;
	color: #333;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 1.4583;
}
.small_dark {
	font-size: 16px;
	color: #333;
	font-weight: 400;
}
.tp-caption .separator,
.tp-caption .separator-2,
.tp-caption .separator-3 {
	width: 500px;
}
.slider-banner-boxedwidth .tp-caption[data-x="left"] {
	margin-left: 20px!important;
}
.slider-banner-boxedwidth .tp-caption[data-x="right"] {
	margin-right: 20px!important;
}
/* Large and Medium devices (desktops, tablets less than 1199px) */
@media (max-width: 1199px) { 
	.tp-caption[data-x="left"] {
		margin-left: 20px!important;
	}
	.tp-caption[data-x="right"] {
		margin-right: 20px!important;
	}
	.caption-box {
		max-width: 480px!important;
		p { 
			font-size: 14px;
			line-height: 1.5;
		}
	}	
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) { 
	.tp-caption .separator,
	.tp-caption .separator-2,
	.tp-caption .separator-3 {
		width: 250px;
	}
	.caption-box {
		max-width: 420px!important;
		width: 100%;
		h2 {
			font-size: 22px;
		}
	}
}
/* Small devices (smartphones less than 480px) */
@media (max-width: 480px) { 
	.caption-box {
		display: none;
	}
}
/*Slider Moving Button*/
.tp-caption .btn.moving {
	padding: 10px 0px 20px;
}

.tp-caption .btn.moving i {
	display: block;
	font-size: 24px;
	line-height: 1px;
	height: 10px;
	color: #ffffff;
	.opacity(1);
}

.tp-caption .btn.moving i + i {
	.opacity(0.66);
}

.tp-caption .btn.moving i + i + i {
	.opacity(0.33);
}

.moving i + i + i {
	-webkit-animation: opacity_first 2s linear infinite;
	animation: opacity_first 2s linear infinite;
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	-ms-transition: none !important;
	transition: none !important;
}

.moving i + i {
	-webkit-animation: opacity_second 2s linear infinite;
	animation: opacity_second 2s linear infinite;
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	-ms-transition: none !important;
	transition: none !important;
}

.moving>i {
	-webkit-animation: opacity_third 2s linear infinite;
	animation: opacity_third 2s linear infinite;
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	-ms-transition: none !important;
	transition: none !important;
}

@-webkit-keyframes opacity_first {
	0% {
		.opacity(0.33);
	}
	24.91% {
		.opacity(1);
	}
	49.833% {
		.opacity(0);
	}
}
@keyframes opacity_first {
	0% {
		.opacity(0.33);
	}
	24.91% {
		.opacity(1);
	}
	49.833% {
		.opacity(0);
	}
}

@-webkit-keyframes opacity_second {
	0% {
		.opacity(0.66);
	}
	16.66% {
		.opacity(1);
	}
	41.5833% {
		.opacity(0);
	}
}
@keyframes opacity_second {
	0% {
		.opacity(0.66);
	}
	16.66% {
		.opacity(1);
	}
	41.5833% {
		.opacity(0);
	}
}

@-webkit-keyframes opacity_third {
	0% {
		.opacity(1);
	}
	33.33% {
		.opacity(0);
	}
}
@keyframes opacity_third {
	0% {
		.opacity(1);
	}
	33.33% {
		.opacity(0);
	}
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) { 
	.tp-caption .btn.moving {
		position: absolute;
		bottom: -100px;
		left: 50%;
		margin-left: -18px;
	}
}

/*Nav Controls*/
/*Prev/Next Nav Controls*/
.tparrows.preview2 {
	background-color: rgba(0, 0, 0, 0.4);
	color: #ffffff;
	.border-radius(0px 3px 3px 0);
}
.tp-rightarrow.preview2 {
	.border-radius(3px 0px 0px 3px);
}
.tparrows.preview2:hover {
	background-color: rgba(0, 0, 0, 0.8);
}
.tparrows.preview2:after {
	content: "";
}
.tparrows.preview2:before {
	font-family: "fontello";
	content: "\e876";
	color: #ffffff;
	font-size: 24px;
	line-height: 60px;
	left: 22px;
	position: absolute;
}
.tp-rightarrow.preview2:before {
	left: auto;
	content: "\e877";
	right: 22px;
}
.tparrows.preview2 .tp-arr-titleholder {
	color: #ffffff;
	text-transform: none;
	font-weight: 300;
	font-family: @font-family-raleway;
}

/* Small devices (tablets, phones 768px and Up) */
@media (min-width: 768px) { 
	.tparrows.preview4 { 
		height:200px; 
	}
	.tparrows.preview4:after { 
		line-height: 200px;
		height: 200px;
	}
	.tparrows.preview4 .tp-arr-imgholder { 
		height:200px; 
	}
	.tparrows.preview4 .tp-arr-imgholder2 { 
		height:200px; 
	}
}

/* Medium devices (laptops, tablets, phones 991px and below) */
@media (max-width: 991px) { 
	.tparrows.preview2:before {
		font-size: 18px;
		line-height: 40px;
		left: 15px;
	}
	.tp-rightarrow.preview2:before {
		left: auto;
		right: 15px;
	}
}
/* Medium devices (desktops, tablets less than 991px) */
@media (min-width: 768px) and (max-width:991px) { 
	.tparrows.preview2 {
		min-width: 40px;
		min-height: 40px;
	}
	.tparrows.preview2 .tp-arr-titleholder {
		font-size: 12px;
		line-height: 40px;
		letter-spacing: 0px;
	}
	.tp-rightarrow.preview2 .tp-arr-titleholder {
		right: 35px;
	}
	.tp-leftarrow.preview2 .tp-arr-titleholder {
		left: 35px;
	}
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) { 
	.tparrows.preview2:before {
		font-size: 16px;
		line-height: 30px;
		left: 10px;
	}
	.tp-rightarrow.preview2:before {
		left: auto;
		right: 10px;
	}	
}

.tp-bullets.preview4 .bullet,
.tp-bullets.preview2 .bullet {
	background: rgba(0, 0, 0, 0.6) !important; 
	border: none!important;
	width: 10px !important;
	height: 10px !important;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin-left: 1px!important;
	margin-right: 1px!important;
}
.tp-bullets.preview4 .bullet:hover,
.tp-bullets.preview4 .bullet.selected,
.tp-bullets.preview2 .bullet:hover,
.tp-bullets.preview2 .bullet.selected {
	background: rgba(0, 0, 0, 1) !important;
	border: none!important;
	width: 10px !important;
	height: 10px !important;	
}

.tp-bannertimer {
	background-image: none;
	background-color: rgba(13, 169, 170, 0.8)!important;
	z-index: 21;
	display: none;
	height: 2px;
}
/* Medium devices (netbooks, tablets, phones less than 991px) */
@media (max-width: 991px) { 
	.slideshow {
		.icon.large {
			font-size: 24px;
			width: 90px;
			height: 90px;
			line-height: 88px;
		}
		.icon.small {
			font-size: 15px;
			width: 35px;
			height: 35px;
			line-height: 35px;
		}
	}
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) { 
	.slideshow {
		.icon.large {
			font-size: 18px;
			width: 60px;
			height: 60px;
			line-height: 58px;
		}
	}
}

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
	.slideshow {
		.icon.large {
			font-size: 18px;
			width: 35px;
			height: 35px;
			line-height: 33px;
		}
	}
}