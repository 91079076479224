/* 3 Sections
----------------------------------------------------------------------------- */
/*Header Top*/
.header-top {
	font-size: 12px;
	color: @gray;
	font-weight: 300;
}
.header-top.dark,
.header-top.colored {
	color: #ffffff;
}
.header-top.colored a {
	color: #ffffff;
	text-decoration: underline;
}
.header-top.colored a:hover {
	text-decoration: none;
}
/* Medium desktop devices and tablets */
@media (min-width:992px) and (max-width:1199px) {
	.header-top {
		font-size: 11px;
	}
}

/*Header*/
.header.dark {
	color: #cacaca;
}

/*Footer*/
.footer {
	font-size: 13px;
	line-height: 1.5;
}

.footer h2.title { 
	font-size: 22px;
	text-transform: none;
	margin-bottom: 10px;
}

/*Subfooter*/
.subfooter {
	font-size: 14px;
	line-height: 1.3;
	color: @gray-light;
}

.subfooter p {
	margin-bottom: 0;
}